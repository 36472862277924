import { template as template_4117ca66d9dd497383dff41de9d30e37 } from "@ember/template-compiler";
const WelcomeHeader = template_4117ca66d9dd497383dff41de9d30e37(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
