import { template as template_b19cc528d4d7426eb28cbaab7a657525 } from "@ember/template-compiler";
const FKControlMenuContainer = template_b19cc528d4d7426eb28cbaab7a657525(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
